.useful {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
  }
  
  .numbers {
    background-color: #2c3e50; /* Darker background for cards */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    max-width: 300px;
    width: 100%;
  }
  
  .txt-big {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 2rem;
    font-weight: 600;
    color: white;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .txt {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
    text-align: left;
  }
  
  @media (max-width: 786px) {
    .useful {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
    .txt-big {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    .txt {
      font-size: 1rem;
    }
  }
  