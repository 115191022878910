@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&family=Tilt+Neon&display=swap');

.our-picks {
    /* background-color: rgba(0, 0, 0, 0.35); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box {

    width: 50%
}

.span-op {
    color: white;
    font-size: 50px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.text-op {
    color: white;
    font-size: 35px;
    font-weight: 300;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.protip {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tip {
    width: 1rem;
    margin: 2rem;
}

.new {
    font-family: "Playwrite CU", sans-serif;
    /* font-family: "Neonderthaw", cursive; */
    /* font-optical-sizing: auto; */
    font-weight: 100;
    font-style: normal;
    position: relative;
    padding: 1rem;
    font-size: 35px;
    color: #0f989c;
    /* color: rgb(173, 215, 224); */
    text-shadow: 0px 0px 10px rgb(90, 164, 186);
    

}