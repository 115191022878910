.nav-menu{
    width: 100%;
    display: flex;
    position: relative;
    /* background-color: #072e339a; */
    justify-content: space-evenly;
    align-items: center;    
}

.nav-menu.fixed {
    width: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
}
#hd-button{
    display: flex;
    color: #6da5c0;
    margin: 1.5rem;
    width: 4rem;
    height: 2rem;
    background-color: #294d61;
    border-radius: 5px;
    border: none;
    display: inline-block; /* Ensure it behaves like a block element */
    text-align: center; /* Center the text */
    line-height: 2rem; /* Vertically center the text */
    text-decoration: none; /* Remove underline */      
}
.back{
    width: 1.2rem;
    color: #ffffff;
}

.back-a{
    font-size: 1.2rem;
    color: rgb(255, 255, 255);
}
.back-b{
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    font-size: 1.2rem;
    color: rgb(255, 255, 255);
    cursor: pointer;
}
.info{
    display: flex;
    flex-direction: column;

}
.logo{
    position: relative;
    margin-top: 1rem;
    background:#ffffff;
    padding: 0.4rem;
    z-index: 1;
    border-radius: 7px;
}
.logo::before{
    content: ''; /* Required for pseudo-elements */
    position: absolute; /* Position the pseudo-element relative to its parent */
    top: 0; /* Adjust the position of the shadow */
    left: 0; /* Adjust the position of the shadow */
    width: 100%; /* Set the width of the shadow to match the parent element */
    height: 100%;
    background:#ffffff85; /* Horizontal offset, vertical offset, blur radius, and color */
    transform: translateY(20px); 
    transform: translateX(23px);/* Translate the element vertically by 5 pixels */
    rotate: 10deg;
    z-index: -1;
    border-radius: 7px;
}
.logo::after{
    text-orientation: mixed; /* Ensure characters are displayed upright */
    content: 'Athens'; /* Required for pseudo-elements */
    color: #072e33;
    font-size: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: absolute;
    rotate: 280deg;
    transform: translateY(-2px);
}

.logo span{
    font-size: 1.8rem;
    font-weight: 600;
    color: #072e33;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    line-height: 0.7; /* Adjust the value to decrease the space between lines */
}
#info{
    font-size: 3.5rem;
    color: #072e33;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.lil{
    color: #ffffff;
    font-size: smaller;
}
.line{
    width: 80%; 
    border: 0.5px solid rgba(241, 241, 241, 0.484);
    background-color: #294d61;
}


@media (max-width: 786px) {
    .nav-menu{
        justify-content: space-around;
    }
    .back-b{
        font-size: 0.7rem;
    }
    .back-a{
        font-size: 0.7rem;
        color: rgb(255, 255, 255);
    }
    .logo span{
        font-size: 1rem;
        font-weight: 600;
        color: #072e33;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        line-height: 0.7; /* Adjust the value to decrease the space between lines */
    }
    #info{
        font-size: 1.8rem;
        color: #072e33;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
    .lil{
        color: #ffffff;
        font-size: 0.5rem;
    }
    .logo::after{
        text-orientation: mixed; /* Ensure characters are displayed upright */
        content: 'Athens'; /* Required for pseudo-elements */
        color: #072e33;
        font-size: 0.5rem;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        position: absolute;
        rotate: 280deg;
        transform: translateY(1px); 
    }
    .logo::before{
        transform: translateX(12px);/* Translate the element vertically by 5 pixels */
    }
}