.struct-ri{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.small-btn svg{
    padding: 1rem;
    width: 9.5rem;
    height: 9.5rem;
}
.small-btn img{
    padding: 1rem;
    width: 9.5rem;
    height: 9.5rem;
}
.museums{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}
.text-m{
    display: flex;
    font-size: 1.3rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    color: white;
    align-items: center;
}
.map{
    display: flex;
    margin: 1.5rem;
}
.beach-ph{
    margin: 1rem;
    margin-top: 1.5rem;
    border-radius: 20px;
    width: 11.5rem;
    height: 11.5rem;
}
.rows2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

}
 #act{
    display: flex;
    justify-content: start;
}

@media (max-width: 786px) {
    .columns {
        width: 20rem;
    }
    .large-btn{
        width: 18rem;
        height: 18rem;
    }
    .small-btn{
        width: 8rem;
        height: 8rem;
    }
    .large-btn img{
        width: 16rem;
        height: 16rem;
    }
    .small-btn img{
        width: 6rem;
        height: 6rem;
    }
    .small-btn svg{
        width: 6rem;
        height: 6rem;
    }
    span{
        font-size: 1rem;
    }
    .services{
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .text{
        font-size: 1.5rem;
        margin: 1rem;
    }
    .map{
        display: flex;
        width: 400;
        height: 400;
        margin: 1.5rem;
    }
    .rows1{
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .rows2{
        flex-direction: column;
        flex-wrap: nowrap;
    }
    
}