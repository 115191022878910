.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
a {
  text-decoration: none;
}
.small-btn:hover{
  transform: scale(1.03); /* Slightly increases the size of the button when hovered */
  transition: all 0.3s ease; /* Adds a smooth transition effect */ 
}
.large-btn:hover{
  transform: scale(1.03); /* Slightly increases the size of the button when hovered */
  transition: all 0.2s ease-in-out; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
}

@keyframes slideIn {
  from {
      opacity: 0;
      filter: blur(5px);
      transform: translateX(-100%);
  }
  to {
      opacity: 1;
      filter: blur(0);
      transform: translateX(0);
  }
}

.animation:nth-child(1) {
  animation: slideIn 1s forwards;
}

.animation:nth-child(2) {
  animation: slideIn 2s forwards;
}

/* down-up */

.hidden2 {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(100%);
}

@keyframes slideIn2 {
  from {
      opacity: 0;
      filter: blur(5px);
      transform: translateY(100%);
  }
  to {
      opacity: 1;
      filter: blur(0);
      transform: translateY(0);
  }
}

.animation2 {
  animation: slideIn2 1s forwards;
}

/* Hide scroll bar in WebKit-based browsers */
body::-webkit-scrollbar {
  display: none;
}

/* For specific element */
.element::-webkit-scrollbar {
  display: none;
}

.header{
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 2;
  background-color: #072e33ce;
  box-sizing: border-box;
}

/* src/components/Loading.css */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #072e33;
  background-size: cover;
  z-index: 2;
}

.turn{
  animation: fadeInOut 1s; /* infinite loop */
}
@keyframes fadeInOut {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}