.services{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.columns {
    width: 27rem;
    justify-content: center; 
    align-items: center; 
}

.rows{
    display: flex;
}

.large-btn{
    margin: 1rem;
    margin-top: 1.5rem;
    background-color: #0f989c;
    position: relative;
    border-radius: 20px;
    width: 25rem;
    height: 25rem;
}
#activities{
    /* border-radius: 50%; */

}

.large-btn img{
    padding: 1rem;
    width: 23rem;
    height: 23rem;
}

span{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
}

.small-btn img{
    padding: 1rem;
    width: 9.5rem;
    height: 9.5rem;
}

.small-btn{
    margin: 1rem;
    margin-top: 1.5rem;
    background-color: #0f989c;
    position: relative;
    border-radius: 20px;
    width: 11.5rem;
    height: 11.5rem;
}
.footer-a {
    padding: 2rem;
    color: white;
}
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-b{
    background: none;
    border: none;
    padding: 2rem;
    font: inherit;
    color: rgb(255, 255, 255);
    cursor: pointer;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    width: 40%;
    height: 40%;
    max-width: 80vw; /* Limiting maximum width to 80% of viewport width */
    max-height: 80vh; /* Limiting maximum height to 80% of viewport height */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    overflow: auto; /* Adding scrollbars when content overflows */
  }  
  
  .close {
    position: absolute;
    top: 80%;
    right: 50%;
    cursor: pointer;
    font-size: 50px; /* Adjust the font size to make the button bigger */
  }
   

@media (max-width: 786px){
    .columns {
        width: 20rem;
    }
    .large-btn{
        width: 18rem;
        height: 18rem;
    }
    .small-btn{
        width: 8rem;
        height: 8rem;
    }
    .small-btn:hover{
        transform: scale(1.05); /* Slightly increases the size of the button when hovered */
        transition: all 0.3s ease; /* Adds a smooth transition effect */ 
    }
    .large-btn img{
        width: 16rem;
        height: 16rem;
    }
    .small-btn img{
        width: 6rem;
        height: 6rem;
    }
    span{
        font-size: 1rem;
    }
    /* .services{
        flex-wrap: wrap;
        justify-content: space-evenly;
    } */
    .small-btn svg{
        width: 6rem;
        height: 6rem;
    }
    .modal-content {
        width: 90%;
        height: 50%;
        font-size: smaller;
    }
    .close {
        right: 46%;
    } 

}
