.container {
    display: flex;
}
.item{
    flex-grow: 1;
    display: flex;

}
  .info-ph {
    width: 100%;
}
.text-info{
    padding: 2rem;
    display: flex;
    font-size: 1.5rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    color: white;
    background-color: #0f969c;
    align-items: center;
}
.view-map{
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scan-ph{
    width: 10%
}
.scan-txt{
    margin: 1rem;
    font-size: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    color: white;
    align-items: center;
}
@media (max-width: 786px) {
    .container {
        flex-wrap: wrap;
    }
    .text-info{
        font-size: 1.2rem;
    }
    .pc-mode{
        display: none;
    }
}
  