.small-btn svg{
    padding: 1rem;
    width: 9.5rem;
    height: 9.5rem;
}
.small-btn img{
    padding: 1rem;
    width: 9.5rem;
    height: 9.5rem;
}
.transport{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}
.text{
    display: flex;
    font-size: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    color: white;
    align-items: center;
}

@media (max-width: 786px) {
    .columns {
        width: 20rem;
    }
    .large-btn{
        width: 18rem;
        height: 18rem;
    }
    .small-btn{
        width: 8rem;
        height: 8rem;
    }
    .large-btn img{
        width: 16rem;
        height: 16rem;
    }
    .small-btn img{
        width: 6rem;
        height: 6rem;
    }
    .small-btn svg{
        width: 6rem;
        height: 6rem;
    }
    span{
        font-size: 1rem;
    }
    .services{
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .text{
        font-size: 1.5rem;
        margin: 1rem;
    }
}