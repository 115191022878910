/* src/components/Login.css */
.login-container {
    max-width: 320px;
    margin: 0 auto;
    padding: 20px;
    background-color: #413e3ea2;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    color: white;
}

label {
    display: block;
    margin-bottom: 10px;
}

input[type="email"],
input[type="password"] {
    width: calc(100% - 20px); /* Adjust width to leave space for padding */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.login-btn {
    width: 100%; /* Make button full width */
    background-color: #4caf50;
    color: #fff;
    padding: 10px 0; /* Adjust padding */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.login-btn:hover {
    background-color: #45a049;
}

.error-msg {
    color: red;
    margin-top: 10px;
}

.logout-btn {
    margin: 2rem; /* Adjust this value as needed */
    border-radius: 5px;
    border: solid black;
    color: white;
    background-color: black;
    font-size: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    padding: 0.5rem;
}
.formContainer {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #f4f4f4;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.formContainer input[type="text"],
.formContainer input[type="email"],
.formContainer textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #fff;
    transition: border-color 0.3s ease; /* Smooth transition on hover */
}

.formContainer textarea {
    resize: vertical;
}

.formContainer input[type="checkbox"] {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.formContainer label {
    font-weight: normal;
    color: #333; /* Darken text color */
}

.formContainer button {
    background-color: #009688; /* Teal color */
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.formContainer button:hover {
    background-color: #00796b; /* Darken color on hover */
}

.error {
    color: red;
}
.terms{
    color: #484df4;
    text-decoration: underline;
}
.success {
    color: green;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    width: 40%;
    height: 40%;
    max-width: 80vw; /* Limiting maximum width to 80% of viewport width */
    max-height: 80vh; /* Limiting maximum height to 80% of viewport height */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    overflow: auto; /* Adding scrollbars when content overflows */
  }  
  
  .close {
    position: absolute;
    top: 80%;
    right: 50%;
    cursor: pointer;
    font-size: 50px; /* Adjust the font size to make the button bigger */
  }
  
  
@media (max-width: 786px) {
    .formContainer {
        width: 80%;
    }
    .modal-content {
        width: 90%;
        height: 50%;
        font-size: smaller;
    }
    .close {
        right: 46%;
    } 
}